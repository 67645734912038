



















import { mapActions, mapGetters } from 'vuex'
import AppHeader from '~/components/car/header/AppHeader.vue'
import { PARKING_NS } from '~/store/modules/shared/parking/state'
import AppFooter from '~/components/car/footer/AppFooter.vue'
import { getRouteMetaItem } from '~/utils/router'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    AppFooter,
    AppHeader,
    ParkingModal: defineVue3AsyncComponent(() =>
      import('~/components/shared/parking/ParkingModal.vue')
    )
  },
  data() {
    return {
      availableLocales: this.$dep(LanguageSwitcher).getAvailableLocales()
    }
  },
  computed: {
    ...mapGetters(PARKING_NS, {
      showParking: 'getShowParking'
    }),
    ...mapDeps({
      languageSwitcherService: LanguageSwitcher
    }),
    isIframe() {
      return getRouteMetaItem(this.$route, 'isIframe')
    },
    isSubdomain() {
      return getRouteMetaItem(this.$route, 'isSubdomain')
    },
    isDealerSite() {
      const dealerSiteService = this.$dep(DealerSiteService)
      return dealerSiteService.routeIsOfDealerSite()
    },
    isNoHeaderRoute() {
      return getRouteMetaItem(this.$route, 'noHeader')
    }
  },
  watch: {
    currentRoute(val) {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales(
        val
      )
    },
    $route() {
      this.availableLocales = this.languageSwitcherService.getAvailableLocales()
    }
  },
  methods: {
    ...mapActions(PARKING_NS, {
      hideParkingModal: 'hideParking'
    })
  },
  head() {
    const { bodyClasses } = this.$store.state.page
    return {
      bodyAttrs: {
        class: bodyClasses.join(' ')
      }
    }
  }
})
